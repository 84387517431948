<template>
    <div class="addsubscribe-main" :class="{'App-main':$store.getters.isApp}">
        <div class="from-container" v-if="!successShow">
            <div class="patient-head">
                <p>{{$t("subscribe.pleasepatient")}}</p>
            </div>
            <div class="patient-container">
                <div class="patient-item" v-for="(item,index) in $store.getters.patientData" :key="index" @click="patientActiveId = item.id">
                    <img src="@/assets/img/patientList-picman.png" v-if="item.gender == 1">
                    <img src="@/assets/img/patientList-picman.png" v-else>
                    <div class="item-info">
                        <p class="name">
                            {{item.name}}
                            <span class="tag">{{relationObj[item.relationship]}}</span>
                        </p>
                        <p class="ageOrgender">
                            <span class="txt">{{item.gender?$t("subscribe.man"):$t("subscribe.woman")}}</span>
                            <span class="txt">{{item.age}}{{$t("subscribe.sui")}}</span>
                        </p>
                    </div>
                    <i class="active-icon" v-if="patientActiveId == item.id"></i>
                </div>
            </div>
            <div class="submit-btn " v-if="!isEdit" :class="{'submit-disable':submitdisable}" @click="submit">
                {{$t("subscribe.dealWith")}}
            </div>
       
        </div>
        <div class="from-container success-container" v-else>
            <img src="@/assets/img/admission-success-icon.png" >
            <p class="sucess-tips">{{$t("subscribe.dealwithSuccess")}}</p>
            <!-- <p class="desc">{{$t("subscribe.successDesc",{name:this.fromData.patientName})}}</p> -->
            <div class="btn" @click="$router.go(-1)">{{$t("subscribe.returnbtn")}}</div>
        </div>
    </div>
</template>

<script>
import { relationObj } from "@/utils/staticData";
import { patientQuery } from "@/api/patient";
import { admissionAdd,  admissionCancel } from "@/api/index";
export default {
    data () {
        return {
            fromData:{
                patientId:"",
                patientName:"",
                remark:""
            },
            relationObj,
            patientActiveId:'',
            successShow:false,

            uploadimg:[],
            isEdit:false,
        }
    },
    computed:{
		getters(){
			return this.$store.getters
		},
        submitdisable(){
            return !this.patientActiveId?true:false;
        }
	},
    watch:{
        'getters':{
			handler(news,old){
				// this.patientInfo = this.$store.getters.patientData.find(item => item.id  == this.$store.getters.selectPatientId);
                if(this.$store.getters.patientSelectIndex != null){
                    let res = this.$store.getters.patientData[this.$store.getters.patientSelectIndex];
                    this.fromData.patientId = res.id;
                    this.fromData.patientName = res.name;
					this.$store.commit("user/SET_PATIENTINDEX",null);
				}
			},
			deep:true
		},
        
    },
    destroyed () {
        this.$store.commit("user/SET_PATIENTINDEX",null);
    },
    created () {
        document.title = this.$t("subscribe.addAdmission")
        this.getPatientData();

    },
    methods:{
        // 获取就诊人数据
		getPatientData(){
			patientQuery({}).then(res=>{
				if(res.data.errorCode == 0){
					if(res.data.data.length !== 0){                        
						this.$store.commit('user/SET_PATIENTDATA',res.data.data);
					}
				}else{
					this.$Toast(this.$t("patient.patientfail"))
				}
			})
		},
        async submit(){
            if(this.submitdisable){
                return;
            }
            const toast = this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: this.$t("message.loadingTxt")
                });
            let datadd = {
                patientId:this.patientActiveId
            };
            admissionAdd(datadd).then(res=>{
                toast.clear();
                if(res.data.errorCode == 0){
                    this.successShow = true;
                }else{
                    this.$toast(res.data.errorMsg)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>

.addsubscribe-main{
    height: 100vh;
    padding-top: 4rem;
    padding-bottom: 0.6rem;
    box-sizing: border-box;
    background: url("~@/assets/img/admission-banner.png") no-repeat;
    background-color: #FE9B5E;
    background-size: auto 7.76rem;
    background-position-y: -2rem;
    display: flex;
    flex-direction: column;
    .from-container{
        flex: 1;
        margin: 0 0.64rem;
        padding: 0 1rem 1rem 1rem;
        background: #FFF1E4;
        border-radius: 0.52rem;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .patient-head{
            height: 2.4rem;
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                font-size: 0.64rem;
                padding: 0 2.4rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #CF7B50;
                background: url("~@/assets/img/admission-tilte-back.png") no-repeat;
                background-size: 100% auto;
                background-position-y: center;
            }
        }
        .patient-container{
            flex: 1;
            overflow: auto;

            .patient-item{
                background: #FFFFFF;
                padding: 0.4rem 0.7rem;
                border-radius: 0.32rem;
                margin-bottom: 0.4rem;
                display: flex;
                align-items: center;
                img{
                    flex: 0 0 auto;
                    height: 2.2rem;
                    width: 2.2rem;
                    margin-right: 0.7rem;
                }
                .item-info{
                    flex: 1;
                    min-height: 1.8rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .name{
                        font-size: 0.64rem;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        display: flex;
                        align-items: flex-start;
                        .tag{
                            font-size: 0.44rem;
                            font-family: PingFang SC;
                            font-weight: 300;
                            color: #49A7FF;
                            padding: 0.04rem 0.18rem;
                            margin-top: 0.05rem;
                            margin-left: 0.3rem;
                            background: #E1EEFD;
                            border-radius: 0.08rem;
                        }
                    }
                    .ageOrgender{
                        font-size: 0.54rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        .txt{
                            margin-right: 00.3rem;
                        }
                    }
                }
                .active-icon{
                    width: 0.96rem;
                    height: 0.96rem;
                    margin-left: 0.2rem;
                    background: url("~@/assets/img/admission-active.png") no-repeat;
                    background-size: 100% 100%;
                }

            }
            .item-active{
                border: 0.06rem solid #FF9048;
            }
        }
        .submit-btn{
            flex: 0 0 auto;
            width: 100%;
            color: #fff;
            height: 2rem;
            background: linear-gradient(0deg, #FF8742 0%, #FFE37E 100%);
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.76rem;
            font-family: PingFang SC;
            font-weight: 500;
        }
        .submit-disable{
            background: #ECEEF3;
            color: #ccc;
        }
        .cancle-btn{
            margin: 1.5rem 0 1rem 0;
        }
    }
    .success-container{
        text-align: center;
        img{
            display: block;
            width: 5.3rem;
            height: 4.8rem;
            margin: 3.5rem auto 0.5rem auto;
        }
        .sucess-tips{
            font-size: 0.8rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
        }
        .desc{
            font-size: 0.64rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            width: 80%;
            margin: 0.4rem auto 2rem auto;
        }
        .btn{
            height: 2rem;
            background: linear-gradient(0deg, #FF8742 0%, #FFE37E 100%);
            border-radius: 1rem;
            margin-top: 1.5rem;
            line-height: 2rem;
            font-size: 0.76rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 3.5rem;
        }
    }
}
.App-main.addsubscribe-main{
    height: 100%;
}
</style>